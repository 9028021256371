import React from 'react';
import ReactTooltip from 'react-tooltip';

import propTypes, { element } from 'prop-types';

import moment from 'moment';

import utils from '../../utils/utils';

import Clock from '../Clock/index';
import _ from 'underscore';
import WeeksControls from '../WeeksControls';

/**
 * This component shows the live info of the fixture
 * @param fixture { Object }
 * @return Component
 */
export const LiveBadge = ({ fixture }) => {
  let quarter = null;
  let statsContent = null;

  if (!fixture || fixture.finished || fixture.enabled || fixture.sort_status !== 0) {
    return null;
  }

  if (fixture.stats && fixture.stats.hasOwnProperty('quarter')) {
    if (fixture.stats.quarter && fixture.stats.quarter !== '') {
      quarter = <span> {fixture.stats.quarter} </span>;
    }
  }
  if (fixture.stats && fixture.stats.hasOwnProperty('timer')) {
    if (fixture.stats.timer && fixture.stats.timer !== '') {
      statsContent = (
        <React.Fragment>
          <span> {fixture.stats.timer} ""</span>
          <i className="ion-ios-timer" />
        </React.Fragment>
      );
    }
  }

  return (
    <span className="live-pool flex-container">
      <div className="flex-col col-20 col-no-padding live-label">Live!</div>
      <div className=" flex-col col-no-padding live-data ">
        <div className="flex-container justify-end">
          {quarter}
          {statsContent}
        </div>
      </div>
    </span>
  );
};

/**
 * This component handles the View and thefunctionallity of the clicked pick
 * @param type              { String }
 * @param pointFormat       { String }
 * @param fixture           { Object }
 * @param handlePick        { Function }
 * @param handleRemovePick  { Function }
 * @param allowTies         { Boolean }
 * @param selectedValue     { Object }
 * @return Component
 */
const Pick = ({
  entryId,
  type,
  pointFormat,
  fixture,
  group,
  itemGroups,
  parleyFlag,
  handlePick,
  handleRemovePick,
  isDisabled,
  allowTies,
  selectedValue,
  maxPicks,
}) => {
  let teamName = null;
  let teamInfo = null;
  let isSelected = false;

  if (
    selectedValue !== undefined &&
    selectedValue !== -1 &&
    selectedValue.fixture_id === fixture.id
  ) {
    if (type === 'line' || type === 'tie') {
      isSelected = selectedValue.pick === 'tie';
    } else {
      isSelected = selectedValue.pick === type;
    }
  }

  let correctClass = null;

  if (type === 'home') {
    teamInfo = fixture.home_team;
    teamName = !fixture.home_team.short_name
      ? fixture.home_team.name
      : fixture.home_team.short_name;

    if ( selectedValue && selectedValue.pick === 'home' ) {
      if(
        ( fixture.sort_status !== 1 && fixture.winning_pick_line !== 'pick' && pointFormat === 'line' && selectedValue.pick === fixture.winning_pick_line) ||
        ( fixture.sort_status !== 1 && fixture.winning_pick      !== 'pick' && pointFormat !== 'line' && selectedValue.pick === fixture.winning_pick)
      ) {
        correctClass = 'gano';
      } else if (
        ( fixture.sort_status !== 1 && fixture.winning_pick_line !== 'tie' && pointFormat === 'line' && selectedValue.pick !== fixture.winning_pick_line) ||
        ( fixture.sort_status !== 1 && fixture.winning_pick      !== 'pick'&& pointFormat !== 'line' && selectedValue.pick !== fixture.winning_pick)
      ) {
        correctClass = 'perdio';
      }
    }
    /****************
     * Home  *
     ****************/
    return (
      <label
        className={`pick-category ${type === 'home' ? 'local' : 'visitante'} ${isSelected ? 'selected' : ''} ${correctClass}`}
        value={type}
        htmlFor={`propFixture-${entryId}-${fixture.id}-home`}
        style={{ position: 'relative' }}
      // onClick={() =>{  if(isDisabled){ return } handlePick('pick', fixture.id, type) }}
      >
        <div className="pick-category flex-container align">
          <div className="category-container row-center flex-row row-no-padding">
            <div className="flex-col team-data col-20">
              <div className="radio-container">
                <input
                  id={`propFixture-${entryId}-${fixture.id}-home`}
                  disabled={isDisabled}
                  name={`propFixture-${fixture.id}`}
                  type="radio"
                  value="home"
                  checked={isSelected}
                  onChange={() => handlePick('pick', fixture.id, type, group, parleyFlag, itemGroups)}
                  onClick={() => handleRemovePick('pick', fixture.id, type, group, parleyFlag, itemGroups)}
                />
                <div className={`radio-check ${isDisabled ? 'disabled' : ''}`}></div>
              </div>
            </div>
            <div className="team-data flex-col col-20">
              {!teamInfo.logo ? <span></span> : <i className={`${teamInfo.logo} ${type}`}></i>}
            </div>
            <div className="flex-col team-data col-60">
              <span className="container-team-record overflow-words">
                <span className="team-name">{teamName}</span>
                {teamInfo.team_stats ? (
                  <span className="record-team soccer away">
                    [{teamInfo.team_stats.win}-{teamInfo.team_stats.ties}-{teamInfo.team_stats.lost}
                    ]
                  </span>
                ) : null}
                {!fixture.period ? null : (
                  <small style={{ fontWeight: 'bold' }}> {fixture.period} </small>
                )}
              </span>
            </div>
            {isSelected && fixture.enabled && maxPicks !== null && (
              <i style={{ right: '-5px' }} className="ion-close-circled delete-pick"></i>
            )}
            {isSelected && fixture.enabled && (parleyFlag !== null && parleyFlag !== undefined) && (
              <i style={{ right: '-5px' }} className="ion-close-circled delete-pick"></i>
            )}
          </div>
        </div>
      </label>
    );
  } else if (type === 'away') {
    /****************
     *  Away *
     ****************/
    teamInfo = fixture.away_team;
    teamName = !fixture.away_team.short_name
      ? fixture.away_team.name
      : fixture.away_team.short_name;

      if ( selectedValue && selectedValue.pick === 'away' ) {
        if(
          ( fixture.sort_status !== 1 && fixture.winning_pick_line !== 'pick' && pointFormat === 'line' && selectedValue.pick === fixture.winning_pick_line) ||
          ( fixture.sort_status !== 1 && fixture.winning_pick      !== 'pick' && pointFormat !== 'line' && selectedValue.pick === fixture.winning_pick)
        ) {
          correctClass = 'gano';
        }
        if (
          ( fixture.sort_status !== 1 && fixture.winning_pick_line !== 'tie' && pointFormat === 'line' && selectedValue.pick !== fixture.winning_pick_line) ||
          ( fixture.sort_status !== 1 && fixture.winning_pick      !== 'pick'&& pointFormat !== 'line' && selectedValue.pick !== fixture.winning_pick)
        ) {
          correctClass = 'perdio';
        }
      }

    return (
      <label
        className={`pick-category ${type === 'home' ? 'local' : 'visitante'} ${isSelected ? 'selected' : ''} ${correctClass}`}
        value={type}
        htmlFor={`propFixture-${entryId}-${fixture.id}-away`}
        style={{ position: 'relative' }}
      // onClick={() =>{ if( isDisabled ){ return } handlePick('pick', fixture.id, type)}}
      >
        <div className="pick-category flex-container align">
          <div className="category-container flex-row row-center row-no-padding">
            <div className="flex-col team-data col-60">
              <span className="container-team-record overflow-words">
                <span className="team-name">{teamName}</span>
                {teamInfo.team_stats ? (
                  <span className="record-team soccer away">
                    [{teamInfo.team_stats.win}-{teamInfo.team_stats.ties}-{teamInfo.team_stats.lost}
                    ]
                  </span>
                ) : null}
                {!fixture.period ? null : (
                  <small style={{ fontWeight: 'bold' }}> {fixture.period} </small>
                )}
              </span>
            </div>
            <div className="team-data flex-col col-20">
              {!teamInfo.logo ? <span></span> : <i className={`${teamInfo.logo} ${type}`}></i>}
            </div>
            <div className="flex-col team-data col-20">
              <div className="radio-container">
                <input
                  id={`propFixture-${entryId}-${fixture.id}-away`}
                  disabled={isDisabled}
                  name={`propFixture-${fixture.id}`}
                  type="radio"
                  value="away"
                  checked={isSelected}
                  onChange={() => handlePick('pick', fixture.id, type, group, parleyFlag, itemGroups)}
                  onClick={() => handleRemovePick('pick', fixture.id, type, group, parleyFlag, itemGroups)}
                />
                <div className={`radio-check ${isDisabled ? 'disabled' : ''}`} />
              </div>
            </div>
            {isSelected && fixture.enabled && maxPicks !== null && (
              <i style={{ left: '-5px' }} className="ion-close-circled delete-pick"></i>
            )}
            {isSelected && fixture.enabled && (parleyFlag !== null && parleyFlag !== undefined) && (
              <i style={{ right: '-5px' }} className="ion-close-circled delete-pick"></i>
            )}
          </div>
        </div>
      </label>
    );
  } else if (type === 'tie' || type === 'line') {
    /*****************
     * Empate y linea *
     *****************/
    // console.log('pointFormat', 'allowTies')
    // console.log(pointFormat, allowTies)
    if (pointFormat === 'line') {
      /**********
       * Linea *
       **********/
      return (
        <div className="pick-category linea">
          {fixture.favorite === null ? (
            <div className="category-container row-center flex-row row-no-padding">PD</div>
          ) : (
              <div className="category-container flex-row row-center row-no-padding linea-container">
                <div className="item-linea flex-col team-data local" style={{minWidth: '20px'}}>
                  {fixture.favorite === 'home' ? fixture.advantage : null}
                </div>
                <div className="item-linea flex-col team-data visita" style={{minWidth: '20px'}}>
                  {fixture.favorite === 'away' ? fixture.advantage : null}
                </div>
              </div>
            )}
        </div>
      );
    } else if (pointFormat === 'natural' && allowTies) {


      if ( selectedValue && selectedValue.pick === 'tie' ) {
        if(
          ( fixture.sort_status !== 1 && fixture.winning_pick_line !== 'pick' && pointFormat === 'line' && selectedValue.pick === fixture.winning_pick_line) ||
          ( fixture.sort_status !== 1 && fixture.winning_pick      !== 'pick' && pointFormat !== 'line' && selectedValue.pick === fixture.winning_pick)
        ) {
          correctClass = 'gano';
        }
        if (
          ( fixture.sort_status !== 1 && fixture.winning_pick_line !== 'tie' && pointFormat === 'line' && selectedValue.pick !== fixture.winning_pick_line) ||
          ( fixture.sort_status !== 1 && fixture.winning_pick      !== 'pick'&& pointFormat !== 'line' && selectedValue.pick !== fixture.winning_pick)
        ) {
          correctClass = 'perdio';
        }
      }
      /**********
       * Empate *
       **********/
      return (
        <label
          className={`pick-category empate  ${isSelected ? 'selected' : ''} ${correctClass}`}
          value={'tie'}
          htmlFor={`propFixture-${entryId}-${fixture.id}-tie`}
        >
          <div className="pick-category flex-container align ">
            <div className="category-container flex-row row-center row-no-padding">
              <div className="flex-col team-data ">
                <div className="radio-container">
                  <input
                    id={`propFixture-${entryId}-${fixture.id}-tie`}
                    disabled={isDisabled}
                    name={`propFixture-${fixture.id}`}
                    type="radio"
                    value="tie"
                    checked={isSelected}
                    onChange={() => handlePick('pick', fixture.id, 'tie')}
                    onClick={() => handleRemovePick('pick', fixture.id, 'tie')}
                  />
                  <div className={`radio-check ${isDisabled ? 'disabled' : ''}`} />
                </div>
              </div>
            </div>
          </div>
        </label>
      );
    } else {
      return (
        <label className={`pick-category empate  `} value={type}>
          <div className="pick-category flex-container align">
            <div className="category-container row-center flex-row row-no-padding">
              <div className="flex-col team-data "></div>
            </div>
          </div>
        </label>
      );
    }
  }
};

/**
 * This Component renders the las cell in the format
 * @param poolInfo                  { Object }
 * @param fixture                   { Object }
 * @param firstFixture              { Object }
 * @param updateStartedFixture      { Function }
 * @param itStarted                 { Boolean }
 * @return Component
 */
const CierreResultado = ({ poolInfo, fixture, firstFixture, updateStartedFixture }) => {
  if (parseInt(fixture.sort_status) !== 1 || fixture.finished === true) {
    return (
      <span className="pick-category resultado ">
        <span>{`${fixture.score_home} - ${fixture.score_away}`}</span>
      </span>
    );
  }
  // timeout

  let now = moment();
  let deadline = moment(fixture.deadline);
  let _deadline = moment(fixture.deadline);
  const isPick5 = poolInfo.max_picks !== null;

  if (isPick5 && firstFixture) {
    _deadline = moment(firstFixture.deadline);
  }
  var timeLeft = deadline.diff(now, 'seconds', true);
  let _timeLeft = _deadline.diff(now, 'millisecond', true);

  if (_timeLeft > 0) {
    if (_timeLeft > 0x7fffffff) {
      _timeLeft = 0x7fffffff;
    }
    setTimeout(() => {
      let _now = moment();
      let sortStatus = deadline.diff(_now) <= 0 ? 0 : 1;

      console.log('CierreResultadoTout', fixture.id, timeLeft, _timeLeft, sortStatus);

      updateStartedFixture(poolInfo.id, fixture.id, sortStatus);
    }, _timeLeft);
  }

  if (timeLeft > 3600) {
    return (
      <span className="pick-category resultado flex-container justify align column">
        <span className="cierre fecha" style={{ color: timeLeft <= 86400 ? '#e00034' : null }}>
          {moment(isPick5 ? firstFixture.deadline : fixture.deadline).format('DD MMM')}
        </span>
        <span className="cierre fecha" style={{ color: timeLeft <= 86400 ? '#e00034' : null }}>
          {moment(isPick5 ? firstFixture.deadline : fixture.deadline).format('H:mm')}
        </span>
      </span>
    );
  }

  return (
    <div className="pick-category resultado 22">
      <Clock
        type="row"
        poolId={poolInfo.id}
        fixtureID={fixture.id}
        closingDate={isPick5 ? firstFixture.deadline : fixture.deadline}
      />
    </div>
  );
};

/**
 * This component renders the list of picks and props
 * @param fixtures                { Object }
 * @param handlePick              { Function }
 * @param handleRemovePick       { Function }
 * @param updateStartedFixture    { Function }
 * @param myEntry                 { Object }
 */
const ListPicks = ({
  fixtures,
  parley,
  firstFixture,
  handlePick,
  handleRemovePick,
  poolInfo,
  updateStartedFixture,
  myEntry,
}) => {
  if (!fixtures || !myEntry) {
    return null;
  }

  //check parlay
  let listPicks;
  if (poolInfo.parlay_flag === null) {
    listPicks = fixtures.map((fixt) => {
      let selectedValue = _.find(myEntry.picks, (myPick) => {
        if (myPick.fixture_id === fixt.id && myPick.pick !== null) {
          return myPick;
        }
      });

      let isDisabled = !fixt.enabled || (utils.isLogged() && utils.isSuPick());

      return (
        <div key={fixt.id} className="row-props-container">
          <LiveBadge fixture={fixt} />
          <div className="row-props results flex-row row-no-padding">
            <Pick
              entryId={myEntry.entry_id}
              selectedValue={selectedValue}
              isDisabled={isDisabled}
              allowTies={poolInfo.sport.allow_ties === 1}
              pointFormat={poolInfo.point_format}
              type="home"
              maxPicks={poolInfo.max_picks}
              isSelected={false}
              fixture={fixt}
              handlePick={handlePick}
              handleRemovePick={handleRemovePick}
            />
            <Pick
              entryId={myEntry.entry_id}
              selectedValue={selectedValue}
              isDisabled={isDisabled}
              allowTies={poolInfo.sport.allow_ties === 1}
              pointFormat={poolInfo.point_format}
              type={poolInfo.format === 'line' ? 'line' : 'tie'}
              maxPicks={poolInfo.max_picks}
              isSelected={false}
              fixture={fixt}
              handlePick={handlePick}
              handleRemovePick={handleRemovePick}
            />
            <Pick
              entryId={myEntry.entry_id}
              selectedValue={selectedValue}
              isDisabled={isDisabled}
              allowTies={poolInfo.sport.allow_ties === 1}
              pointFormat={poolInfo.point_format}
              type="away"
              maxPicks={poolInfo.max_picks}
              isSelected={false}
              fixture={fixt}
              handlePick={handlePick}
              handleRemovePick={handleRemovePick}
            />
            <CierreResultado
              poolInfo={poolInfo}
              fixture={fixt}
              firstFixture={firstFixture}
              updateStartedFixture={updateStartedFixture}
            />
          </div>
        </div>
      );
    });
  } else {
    let group = 0;
    let itemGroups = [];
    listPicks = parley.map((item, index) => {
      //convert item to use map
      let tmpList = [];
      _.each(item, (val, key) => {
        tmpList.push(val);
      })

      group++;
      return (
        <div key={index}>
          <div className="parlay-header" key={index}>
            <div>Partidos #{group}</div>
            <div className='parley-deadline'>{moment(firstFixture.deadline).format('DD MMM YYYY HH:mm') || ''}</div>
          </div>

          {tmpList.map((fixt) => {
            //search fixture parley in fixtures
            let fixtureParley = fixtures.find((element) => element.id === fixt.id)

            let selectedValue = _.find(myEntry.picks, (myPick) => {
              if (myPick.fixture_id === fixtureParley.id && myPick.pick !== null) {
                return myPick;
              }
            });
            let isDisabled = !fixtureParley.enabled || (utils.isLogged() && utils.isSuPick());

            if (selectedValue !== undefined) {
              itemGroups.push({group: group, entryId: selectedValue.fixture_id});
            }

            return(
              <div key={fixt.id} className="row-props-container">
                <LiveBadge fixture={fixtureParley} />
                <div className="row-props results flex-row row-no-padding">
                  <Pick
                    entryId={myEntry.entry_id}
                    selectedValue={selectedValue}
                    isDisabled={isDisabled}
                    allowTies={poolInfo.sport.allow_ties === 1}
                    pointFormat={poolInfo.point_format}
                    type="home"
                    maxPicks={poolInfo.max_picks}
                    isSelected={false}
                    fixture={fixtureParley}
                    group={group}
                    itemGroups={itemGroups}
                    parleyFlag={poolInfo.parlay_flag}
                    handlePick={handlePick}
                    handleRemovePick={handleRemovePick}
                  />
                  <Pick
                    entryId={myEntry.entry_id}
                    selectedValue={selectedValue}
                    isDisabled={isDisabled}
                    allowTies={poolInfo.sport.allow_ties === 1}
                    pointFormat={poolInfo.point_format}
                    type={poolInfo.format === 'line' ? 'line' : 'tie'}
                    maxPicks={poolInfo.max_picks}
                    isSelected={false}
                    fixture={fixtureParley}
                    group={group}
                    parleyFlag={poolInfo.parlay_flag}
                    handlePick={handlePick}
                    handleRemovePick={handleRemovePick}
                  />
                  <Pick
                    entryId={myEntry.entry_id}
                    selectedValue={selectedValue}
                    isDisabled={isDisabled}
                    allowTies={poolInfo.sport.allow_ties === 1}
                    pointFormat={poolInfo.point_format}
                    type="away"
                    maxPicks={poolInfo.max_picks}
                    isSelected={false}
                    fixture={fixtureParley}
                    group={group}
                    itemGroups={itemGroups}
                    parleyFlag={poolInfo.parlay_flag}
                    handlePick={handlePick}
                    handleRemovePick={handleRemovePick}
                  />
                  <CierreResultado
                    poolInfo={poolInfo}
                    fixture={fixtureParley}
                    firstFixture={firstFixture}
                    updateStartedFixture={updateStartedFixture}
                  />
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  }

  return <form className="1" >{listPicks}</form>;
};

/**
 * This Component Handles the Title Picks
 * @param fixture    { Object }
 * @param poolInfo   { Object }
 * @return Component
 */
const TitlePicks = ({ fixture, poolInfo, fixtures }) => {
  // Si hay scroll en picks agregamos padding para que coincidan las columnas
  const paddingRight = fixtures.length > 11 ? 7 : 0

  return (
    <ul className="header-picks-partidos flex-row row-no-padding results" style={{ paddingRight }}>
      <li className="pick-category local"> LOCAL</li>
      {poolInfo.point_format === 'line' ? (
        <li className="pick-category linea"> LÍNEA</li>
      ) : (
          <li className="pick-category empate">{poolInfo.sport.allow_ties ? 'EMPATE' : ''}</li>
        )}
      <li className="pick-category visitante"> VISITANTE</li>
      <li className="pick-category resultado"> {poolInfo.parlay_flag === true ? 'FECHA' : 'CIERRE'}</li>
    </ul>
  );
};

/**
 * This component Renders the title and the list of picks
 */
class Picks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    return (
      <div>
       
        <div className="flex-row title-quiniela sub">

       
          <span className='font-chivo'>PARTIDOS</span>
          {this.props.poolInfo.pool.sport_id !== 2 ? null : (
            <span>
              <ReactTooltip id="sport-time-tooltip" type="light" effect="solid">
                Se tomará en cuenta tiempo extra
              </ReactTooltip>
              <i data-tip data-for="sport-time-tooltip" className="pull-right ion-help-circled"></i>
            </span>
          )}
        </div>
        <TitlePicks
          fixture={this.props.fixtures}
          poolInfo={this.props.poolInfo.pool}
          fixtures={this.props.fixtures}
        />
        <ListPicks
          fixtures={this.props.fixtures}
          parley={this.props.parley}
          firstFixture={this.props.firstFixture}
          poolInfo={this.props.poolInfo.pool}
          handlePick={this.props.handlePick}
          handleRemovePick={this.props.handleRemovePick}
          updateStartedFixture={this.props.updateStartedFixture}
          myEntry={this.props.myEntry}
        />
      </div>
    );
  }
}

Picks.propTypes = {
  fixtures: propTypes.array.isRequired,
  fixtureProps: propTypes.array,
  myEntry: propTypes.object,
  handlePick: propTypes.func.isRequired,
  handleRemovePick: propTypes.func.isRequired,
};

export default Picks;
